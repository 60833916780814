import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Carousel from "react-bootstrap/Carousel";
import AppsIcon from '@material-ui/icons/Apps';
import blueGrey from '@material-ui/core/colors/blueGrey';
import "bootstrap/dist/css/bootstrap.min.css"

import "./portfolioitem.scss"

const PortfolioItem = ({ pageContext }) => {

  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <Layout>
      <SEO
        title={pageContext.yoast.title}
        description={pageContext.yoast.metadesc}
        ogimg={pageContext.yoast.opengraph_image.source_url}
        link={pageContext.link}
      />
      <div className="portfolio-item-wrapper">
        <article className="slideshow-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-1">
                <Link 
                  to={`/portfolio/`} 
                  title="Back to Portfolio" 
                  name="Back to Portfolio"
                >
                  <AppsIcon 
                    fontSize="large" 
                    style={{ color: blueGrey[50] }} 
                  />
                </Link>
              </div>
              <div className="col-10">
                <h1>
                  {pageContext.title}
                </h1>
              </div>
              <div className="col-1">
                &nbsp;
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="carousel-wrapper">
                  <Carousel fade interval={8000}>
                    {
                      pageContext.acf.slides.map((e, i) => (
                      <Carousel.Item key={i} data-pause="true">                        
                        {e.slide_link
                           ? <Link to={e.slide_link}><img className="d-block w-100" src={e.slideImage.localFile.childImageSharp.fluid.srcWebp} alt={e.title} /></Link>
                           : <div><img className="d-block w-100" src={e.slideImage.localFile.childImageSharp.fluid.srcWebp} alt={e.title} /></div>
                        }
                        {/* <Carousel.Caption dangerouslySetInnerHTML={{ __html: e.slide_text }} /> */}
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  );
};

export default PortfolioItem;
